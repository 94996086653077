<template>
  <div class="mainAppContainer backgroundBingo">
    <keep-alive>
      <div
        id="bingoViewContainer"
        class="bingoViewContainer"
        v-if="showComponent"
      >
        <BingoMachine :dataGame="startGame" :sounds="sounds" />
        <Bingo />
        <InfoBingo />
      </div>
    </keep-alive>
    <div
      id="dialogPrize"
      class="containerDialog"
      v-if="showComponent"
    >
      <DialogPrize :sounds="soundsPrize" />
    </div>
    <div
      id="dialogClosePlay"
      class="containerDialog"
      v-if="endGamen"
    >
      <DialogClosePlay />
    </div>
    <keep-alive>
      <div
        id="preRaffle"
        class="preRaffleMain"
        v-if="showAnticipation"
      >
        <PreRaffle />
      </div>
    </keep-alive>
  </div>
</template>

<script>
import BingoMachine from '../components/BingoMachine.vue';
import Bingo from '../components/Bingo.vue';
import InfoBingo from '../components/InfoBingo.vue';
import DialogPrize from '../components/DialogPrize.vue';
import DialogClosePlay from '../components/DialogClosePlay.vue';
import PreRaffle from '../components/PreRaffle.vue';

export default {
  name: 'BingoView',
  components: {
    BingoMachine,
    Bingo,
    InfoBingo,
    DialogPrize,
    DialogClosePlay,
    PreRaffle,
  },
  data() {
    return {
      activeViewBingo: false,
      sounds: [],
      soundsPrize: [],
      totalBalls: 90,
    };
  },
  mounted() {
    this.loadSounds();
    this.loadSoundsPrize();
  },
  computed: {
    showAnticipation() {
      return this.$store.getters.showAnticipation;
    },
    showComponent() {
      return this.$store.getters.showGame;
    },
    startGame() {
      const gameData = this.$store.state.startGame;
      return gameData;
    },
    endGamen() {
      return this.$store.getters.showCompletedGame;
    },
  },
  methods: {
    loadSounds() {
      try {
        // eslint-disable-next-line no-plusplus
        for (let index = 1; index <= this.totalBalls; index++) {
          // eslint-disable-next-line global-require, import/no-dynamic-require
          const urlSound = require(`../assets/sounds/${index}.mp3`);
          if (urlSound) {
            const audio = new Audio(urlSound);
            this.sounds.push(audio);
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading sound', error);
      }
    },
    loadSoundsPrize() {
      const names = ['coins', 'celebration', 'prize_Quadra', 'prize_Linha', 'prize_Bingo'];
      try {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < names.length; index++) {
          const name = names[index];
          // eslint-disable-next-line import/no-dynamic-require, global-require
          const urlSound = require(`../assets/sounds/${name}.mp3`);
          if (urlSound) {
            const audio = new Audio(urlSound);
            this.soundsPrize.push({
              name,
              source: audio,
            });
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading sound', error);
      }
    },
  },
};
</script>
