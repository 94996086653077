<template>
  <div class="containerRight">
    <div class="containerDrawInfo">
      <div class="drawInfoTitle winnersHeader text-subtitleLarge text-bold text-center colorOrange">
        RODADAD ATUAL
      </div>
      <div class="drawInfoValue winnersFooter gradient1">
        <div class="drawInfoItem text-subtitle text-bold colorWhite">
          <div class="divisor">
            {{getPhrase('number')}}:
            {{currentRaffleData.matchId}}
          </div>
        </div>
        <div class="drawInfoItem text-subtitle text-bold colorWhite">
          <div>
            {{getPhrase('value')}}:
            {{currentRaffleData.cardValue}}
          </div>
        </div>
      </div>
    </div>
    <div class="containerPrizes">
      <div
        class="backPrize"
        v-for="(prize, index) in showPrize"
        v-bind:key="index"
      >
        <div :class="`backPrize_${index} ${prize.shown} ${prize.showAnimation || ''}`">
          <div class="containerTrophy">
            <v-img
              width="90%"
              height="100%"
              :class="`${prize.disable}`"
              :id="'trophy-' + index"
              src="../assets/image/trophy.png"
            />
          </div>
          <div class="containerPrizeName textPrize text-bold colorWhite">
            {{prize.name}}
          </div>
          <div :class="`textPrize text-bold colorOrange ${prize.greyColor}`">
            {{prize.value}}
          </div>
        </div>
      </div>
    </div>
    <div class="containerWinners"
      v-if="participants.length > 0"
    >
      <div class="winnersHeader text-subtitle-small text-bold text-center colorOrange">
        {{getPhrase('participants')}}
      </div>
      <div
        v-for="(participant, index) in participants"
        v-bind:key="index"
      >
        <div
          :id="'row_' + index"
          :key="index"
          :class="(index % 2 === 0) ? 'winnersRow gradient1' : 'winnersRow gradient2'"
        >
          <div class="containerCardName textCenter">
            <div class="text-subtitle-medium colorWhite textFontNormal">
              {{participant.userId}}
            </div>
            <div class="text-subtitle-small colorOrange">
              {{participant.cardId}}
            </div>
          </div>
          <div class="containerCardValue text-center">
            <div
              v-for="(value, indexCard) in participant.missingNumbers.slice(0,6)"
              :key="indexCard"
              class="cardValue text-subtitle-small"
            >
              <div>
                {{(value > 9) ? value : `0${value}`}}
              </div>
            </div>
            <div
              class="cardValue text-subtitle-small"
              v-if="participant.position !== 'BI'"
            >
              {{participant.position}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APP_UTILS from '../config/app_utils';
import APP_CONFIG from '../config/app_config';

import '../css/infoBingo.css';
/* eslint-disable no-console */
/* eslint-disable no-plusplus */

export default {
  name: 'InfoBingo',
  data() {
    return {
      ballsInBoard: 90,
      indexPrizes: 0,
      nextPrizesData: [],
      showPrize: [],
      currentDateId: null,
      currentPrizeId: null,
      isTvBox: false,
    };
  },
  beforeMount() {
    this.isTvBox = this.$store.getters.getChannel;
  },
  computed: {
    currentRaffleData() {
      const raffleData = this.$store.getters.currentRaffleData;
      if (typeof raffleData.matchId === 'undefined') {
        this.clearData();
      }
      this.changePrizeData(raffleData.nextPrizes);
      return raffleData;
    },
    awardedPrizes() {
      const awarded = [...this.$store.getters.awardedPrizes];
      return awarded;
    },
    participants() {
      return this.$store.getters.participants;
    },
  },
  methods: {
    /* updatePrize() {
      const maxPrize = 3;

      if (this.nextPrizesData && this.nextPrizesData.length && this.showPrize.length > maxPrize) {
        const nextPrizes = [...this.nextPrizesData];
        let startIndex = this.indexPrizes;
        let endIndex = startIndex + maxPrize;
        let showPrize = nextPrizes.slice(startIndex, endIndex);

        if (showPrize.length < maxPrize) {
          startIndex = 0;
          endIndex = maxPrize - showPrize.length;
          const diffItems = nextPrizes.slice(startIndex, endIndex);
          showPrize = [...showPrize, ...diffItems];

          const timeout = 3000;
          clearTimeout(this.currentPrizeId);
          this.currentPrizeId = null;
          // Recursive function
          this.currentPrizeId = setTimeout(this.updatePrize, timeout);
        }

        this.showPrize = showPrize;
        this.indexPrizes = endIndex;
      } else {
        this.showPrize = [...this.nextPrizesData];
      }
    }, */
    changePrizeData(nextPrizes) {
      if (typeof nextPrizes === 'undefined' || (nextPrizes?.length === 0 && this.awardedPrizes?.length === 0)) {
        return;
      }

      let newNextPrizes = nextPrizes.filter((p) => p.prizeId !== APP_CONFIG.PRIZE_ID_JACKPOT);
      if (newNextPrizes.length) {
        newNextPrizes[0].showAnimation = 'showCurrentPrize pulseAnimation';
      }

      // validate the prizes shown
      if (this.awardedPrizes && this.awardedPrizes.length) {
        let awardedPrizes = [...this.awardedPrizes];
        awardedPrizes = awardedPrizes.filter((prize) => {
          if (prize.prizeId === APP_CONFIG.PRIZE_ID_BINGO && prize.hasJackpot) {
            return false;
          }

          const parsePrize = prize;
          parsePrize.value = APP_UTILS.formatCurrency(prize.originalValue);
          parsePrize.shown = 'backPrizeShown';
          parsePrize.disable = 'trophyOpacity';
          parsePrize.greyColor = 'colorGrey';
          return parsePrize;
        });
        const matchPrize = [...newNextPrizes, ...awardedPrizes];
        newNextPrizes = matchPrize.sort((a, b) => a.prizeId - b.prizeId);
      }

      this.nextPrizesData = newNextPrizes;
      this.showPrize = [...this.nextPrizesData];
      /* clearTimeout(this.currentPrizeId);
      this.currentPrizeId = null;
      this.updatePrize(); */
    },
    clearData() {
      this.nextPrizesData = [];
      this.showPrize = [];
      clearTimeout(this.currentPrizeId);
      this.currentPrizeId = null;
    },
    getPhrase(phraseId) {
      const phrase = APP_UTILS.getPhrase(phraseId);
      return phrase;
    },
  },
};
</script>
