<template>
  <section class="containerLeft">
    <!-- <div :class="(isTvBox) ? 'spriteMain spriteTvBox' : 'spriteMain'">
      <div
        v-if="isTvBox"
        id="sprite-container"
        class="static-container"
      >
        <div id="sprite-image" class="bolilleroStatic"></div>
      </div>
      <div
        v-else
        id="sprite-container"
        class="sprite-container"
      >
        <div id="sprite-image" class="loop"></div>
      </div>
    </div> -->
    <div class="bigBallContainer">
      <div
        id="bigBall"
        :class='"bigBall ball_image "+bigBallImage'
      >
        <div
          id="textBigBall"
          class="textBigBall"
        >
          {{bigBallNumber}}
        </div>
      </div>
      <div class="bigBallFront"></div>
    </div>
    <div class="ballsContainer">
      <div
        v-for="index in totalBalls"
        :id="'smallBall-'+(index - 1)"
        v-bind:key="index"
        :class="'smallBall ball_image ball_image_'+((index-1) % totalImages)"
      >
        <div
          :id="'textBall-' + (index - 1)"
          class="textBall"
        >
          {{(index >= 10) ? (index) : (`0${index}`)}}
        </div>
      </div>
      <div class="track-container"></div>
    </div>
  </section>
</template>

<script>
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-dynamic-require */
import gsap from 'gsap';
import constants from '../constants/constants';
import '../css/bingoMachine.css';

const ballPositionsMap = [
  { pos: { x: '68%', y: '80%' }, level: 3, rot: '1440_cw' },
  { pos: { x: '35%', y: '80%' }, level: 3, rot: '720_cw' },
  {
    pos: { x: '1%', y: '80%' }, level: 3, rot: '360_cw', medium: true,
  },
  { pos: { x: '-8%', y: '63%' }, level: 2, rot: '360_ccw' },
  { pos: { x: '24%', y: '58%' }, level: 2, rot: '360_ccw' },
  {
    pos: { x: '57%', y: '55%' }, level: 2, rot: '360_ccw', medium: true,
  },
  { pos: { x: '68%', y: '38%' }, level: 1, rot: '1080_cw' },
  { pos: { x: '35%', y: '34%' }, level: 1, rot: '720_cw' },
  { pos: { x: '2%', y: '32%' }, level: 1, rot: '360_cw' },
];

// eslint-disable-next-line no-unused-vars
export default {
  name: 'IdleApp',
  props: {
    dataGame: Object,
    sounds: Array,
  },
  data() {
    return {
      animationBolilleroID: null,
      ballOntrack: 0,
      ballsInBoard: [],
      bigBallImage: '',
      bigBallNumber: '',
      currentIndexBall: 0,
      // dispatchNewBallId: null,
      heightOfEachSprite: 852.5,
      index_X_Sprite: 0,
      index_Y_Sprite: 0,
      isRecoverGame: false,
      isTvBox: false,
      maxBallOnTrack: 9,
      moveOnePositionId: null,
      raffleData: this.dataGame.results || [],
      raffledBalls: [],
      soundList: this.sounds || [],
      speedBolillero: 60,
      startBingoSound: false,
      totalBalls: 90,
      totalImages: 10,
      totalSpriteDesktop: 60,
      totalSprites_X: 4,
      totalSprites_Y: 5,
      totalSpriteTvBox: 9, // 15,
      widthOfEachSprite: 802,
    };
  },
  beforeMount() {
    this.isTvBox = this.$store.getters.getChannel;
  },
  mounted() {
    /* if (spriteSheet) {
      const spriteSheet = document.getElementById('sprite-image');
      this.startAnimation(spriteSheet);
    } */

    if (this.dataGame?.results?.length) {
      this.configAnimations();
      if (this.dataGame.isRecoverRaffle) {
        this.isRecoverGame = true;
        this.recoverGame();
      } else {
        this.newBall();
      }
    }
  },
  computed: {
    muteActive() {
      return this.$store.getters.getActiveMute;
    },
  },
  watch: {
    dataGame() {
      const raffleData = this.dataGame.results;
      this.raffleData = raffleData;

      if (raffleData?.length > this.ballsInBoard.length && !this.isRecoverGame) {
        this.newBall();
      }

      return !!this.raffleData.length;
    },
  },
  methods: {
    notifyNewBall(idBall) {
      this.$store.dispatch(constants.ADD_NEW_IDLE_BALL, idBall);
    },
    recoverGame() {
      this.raffleData.forEach((idBall) => {
        this.ballsInBoard.push(idBall);
        this.notifyNewBall(idBall);
      });

      this.isRecoverGame = false;
      this.$store.dispatch(constants.UPDATE_RECOVER_GAME, false);
    },
    newBall() {
      if (!this.raffleData.length) {
        this.clearData();
        return;
      }

      const newBallCallback = () => {
        const index = this.ballsInBoard.length;
        const idBall = this.raffleData[index];
        const sound = this.getSound(idBall);
        // const muteActive = this.muteActive || this.raffleData.length > this.raffledBalls.length + 1;

        this.currentIndexBall = this.raffledBalls.length;
        this.raffledBalls.push(idBall);
        this.ballsInBoard.push(idBall);
        this.ballOntrack += 1;
        // Start Animations
        this.getInBigBallAnimation(idBall);
        this.getInSmallBallAnimation(idBall);

        if (sound) { this.playSound(sound); }

        this.notifyNewBall(idBall);
        /* this.dispatchNewBallId = setTimeout(() => {
          clearTimeout(this.dispatchNewBallId);
          this.dispatchNewBallId = null;

        }, 1000); */
      };

      if (this.ballOntrack >= this.maxBallOnTrack) {
        this.moveOnePosition(newBallCallback);
        return;
      }

      newBallCallback && newBallCallback();
    },
    setMapValues(ball) {
      const map = ballPositionsMap[ball.index];

      ball.map = map;
      ball.level = map.level;
      ball.medium = map?.medium || false;
    },
    // The animations keep running without focus
    configAnimations() {
      gsap.ticker.lagSmoothing(false);

      if (this.isTvBox) {
        // set frame rate lower
        gsap.ticker.fps(55);
      }
    },
    clearData() {
      this.killAnimations();

      /* clearTimeout(this.dispatchNewBallId);
      this.dispatchNewBallId = null; */
      clearTimeout(this.moveOnePositionId);

      this.ballsInBoard = [];
      this.bigBallImage = '';
      this.bigBallNumber = '';
      this.moveOnePositionId = null;
      this.raffleData = [];
      this.raffledBalls = [];
      this.startBingoSound = false;
    },
    killAnimations() {
      const smallBalls = document.querySelectorAll('.smallBall');
      if (smallBalls && smallBalls.length > 0) {
        smallBalls.forEach((smallBall) => this.resetValueBall(smallBall));
      }
    },
    resetValueBall(ball) {
      if (!ball) { return; }

      ball.index = 0;
      ball.style.visibility = 'hidden';
      gsap.killTweensOf(ball);
    },
    getSound(nameSound) {
      if (!this.soundList.length) { return false; }

      const audio = this.soundList[nameSound - 1];
      return audio;
    },
    playSound(audio) {
      audio.play()
        .catch((param) => {
          // eslint-disable-next-line no-console
          console.info('Audio Error', param);
        });
    },
    // ********** ANIMATIONS ********** /
    // Stop animation of Bolillero Bingo
    stopAnimation() {
      clearInterval(this.animationBolilleroID);
      this.animationBolilleroID = null;
    },
    // Start animation of Bolillero Bingo
    /* startAnimation(spriteSheet) {
      if (this.isTvBox) {
        return;
      }

      const speed = this.speedBolillero; // in millisecond(ms)
      const totalFrames = this.totalSpriteDesktop - 1;
      let indexSprite = 0;

      this.animationBolilleroID = setInterval(() => {
        spriteSheet.classList.add(`loop-layer-${indexSprite}`);
        if (indexSprite >= 1) {
          spriteSheet.classList.remove(`loop-layer-${indexSprite - 1}`);
        } else if (indexSprite === 0) {
          spriteSheet.classList.remove(`loop-layer-${totalFrames}`);
        }
        indexSprite = (indexSprite >= totalFrames) ? 0 : (indexSprite + 1);
      }, speed);
    }, */
    // ***** TRACK ANIMATION *****/
    getInBigBallAnimation(idBall) {
      const bigBall = document.getElementById('bigBall');

      if (bigBall) {
        this.bigBallImage = `ball_image_${(idBall - 1) % this.totalImages}`;
        this.bigBallNumber = (idBall >= 10) ? idBall : `0${idBall}`;

        bigBall.classList.remove('scalaInAnimation');

        this.getInBigBallAnimationID = setTimeout(() => {
          clearTimeout(this.getInBigBallAnimationID);
          this.getInBigBallAnimationID = null;

          bigBall.classList.add('scalaInAnimation');
        }, 250);
      }
    },
    getInSmallBallAnimation(idBall) {
      const nameId = `smallBall-${idBall - 1}`;
      const smallBall = document.getElementById(nameId);

      if (smallBall) {
        const index = (this.currentIndexBall >= ballPositionsMap.length)
          ? ballPositionsMap.length - 1 : this.currentIndexBall;
        const tl = gsap.timeline();

        smallBall.index = index;
        smallBall.tween = tl;
        smallBall.style.visibility = 'visible';
        this.setMapValues(smallBall);

        if (smallBall.level === 1) {
          this.movementEndBall(smallBall, tl);
        } else {
          this.movementBallLevel1(smallBall, tl);
        }
      }
    },
    movementBallLevel1(ball, tween) {
      if (!ball && !tween) return;

      tween.to(ball, {
        duration: 0.8,
        ease: 'sine.out',
        left: '69%',
        top: '37%',
        rotation: '720_cw',
        transformOrigin: '50% 50%',
        onComplete: () => this.movementDownLevel1(ball, tween),
        callbackScope: this,
      });
    },
    movementDownLevel1(ball, tween, updatePosition) {
      if (!ball && !tween) return;

      const callback = () => {
        if (ball.level > 2) {
          this.movementBallLevel2(ball, tween);
        } else {
          this.movementEndBall(ball, tween);
        }
      };

      tween.to(ball, {
        duration: 0.3,
        delay: (updatePosition) ? 0.2 : 0,
        ease: 'bounce.out',
        top: '53%',
        left: '66%',
        onComplete: () => callback(ball, tween),
      });
    },
    movementBallLevel2(ball, tween) {
      if (!ball && !tween) return;

      tween.to(ball, {
        duration: 0.8,
        ease: 'sine.out',
        left: '-5%',
        top: '60%',
        rotation: '0_ccw', // -1080,
        transformOrigin: '50% 50%',
        onComplete: () => this.movementDownLevel2(ball, tween),
      });
    },
    movementDownLevel2(ball, tween, updatePosition) {
      if (!ball && !tween) return;

      tween.to(ball, {
        duration: 0.3,
        delay: (updatePosition) ? 0.2 : 0,
        ease: 'bounce.out',
        top: '80%',
        left: '1%',
        onComplete: () => this.movementEndBall(ball, tween),
      });
    },
    movementEndBall(ball, tween, updatePosition) {
      if (!ball && !tween) return;

      if (updatePosition && ball.medium && ball.level === 2) {
        this.movementDownLevel1(ball, tween, updatePosition);
        return;
      }

      if (updatePosition && ball.medium && ball.level === 3) {
        this.movementDownLevel2(ball, tween, updatePosition);
        return;
      }

      const { map } = ball;
      const animParams = {
        duration: 0.8,
        ease: 'sine.out',
        top: map.pos.y,
        left: map.pos.x,
      };

      if (!map.medium) {
        animParams.rotation = map.rot;
        animParams.transformOrigin = '50% 50%';
      }

      tween.to(ball, animParams);
    },
    outBallAnimation(ball, tween) {
      if (!ball && !tween) return;

      tween.to(ball, {
        duration: 0.3,
        ease: 'none',
        scale: 0,
        onComplete: () => tween.kill(),
      });
    },
    moveOnePosition(callback) {
      // eslint-disable-next-line no-plusplus
      for (let index = this.raffledBalls.length - 1; index >= 0; index--) {
        const idBall = this.raffledBalls[index];
        const nameId = `smallBall-${idBall - 1}`;
        const ball = document.getElementById(nameId);

        if (ball && ball.style.visibility === 'visible') {
          const newIndexball = ball.index - 1;
          const tween = ball.tween || gsap.timeline();

          if (newIndexball >= 0) {
            const updatePosition = true;
            ball.index = (newIndexball >= ballPositionsMap.length)
              ? 0 : newIndexball;
            this.setMapValues(ball);
            this.movementEndBall(ball, tween, updatePosition);
          } else {
            this.outBallAnimation(ball, tween);
          }
        }
      }
      this.ballOntrack -= 1;

      this.moveOnePositionId = setTimeout(() => {
        clearTimeout(this.moveOnePositionId);
        this.moveOnePositionId = null;

        callback && callback();
      }, 250);
    },
  },
};
</script>
