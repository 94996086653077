<template>
  <div id="DialogError" class="containerFS_Dialog">
    <div class="backgroundFS_Dialog backgroundBingo">
      <div class="titleDialogError text-xlarge colorBlack">
        {{title}}
      </div>
    </div>
  </div>
</template>

<script>
import APP_TEXTS from '../languages/language_PT';

export default {
  name: 'DialogError',
  data() {
    return {
    };
  },
  computed: {
    title() {
      const raffling = this.$store.getters.stateRaffling;
      const textTitle = (raffling) ? APP_TEXTS.gameStopped : this.getError();
      return textTitle;
    },
  },
  methods: {
    getError() {
      if (!window.navigator.onLine) {
        return APP_TEXTS.noConnection;
      }

      const errorData = this.$store.getters.getMessageError;
      const textTitle = (errorData && errorData.content)
        ? errorData.content
        : APP_TEXTS.tableInMaintenance;

      return textTitle;
    },
  },
};
</script>
