<template>
  <div id="bingoPresenterIdle" class="mainIdleContainer">
    <div class="carrosselContainer">
      <div
        v-show="imagesAvailable"
        class="imageContainer"
        id="imageContainer"
      >
        <img
          id="currentImage"
          :src="currentImageURL"
          alt="imagens carroussel de Divertidu"
          class="image"
        />
      </div>
    </div>
    <div v-show="showJackpotBall" id="containerJackpot" class="containerJackpotBall">
      <div class=" text-large text-bold colorBlack pt-1">
        {{ jackpotValue }}
      </div>
      <div class=" text-large text-bold colorBlack pt-8">
        {{ jackpotBall }}
      </div>
    </div>
    <div v-show="showTime" class="containerTimer">
      <div class="text-subtitle text-bold colorWhite pt-3">
        {{getPhrase('nextRaffleIn')}}
      </div>
      <div class="text-large text-bold colorOrange pt-2 pb-4">
        {{ time }}
      </div>
    </div>
  </div>
</template>

<script>
import '../css/carrossel.css';
import gsap from 'gsap';
import APP_UTILS from '../config/app_utils';

export default {
  name: 'CarrosselImage',
  props: {
    time: String,
  },
  data() {
    return {
      currentId: 0,
      currentImageURL: '',
      gameType: '',
      images: [],
      jackpotBall: 0,
      jackpotValue: 0,
      showJackpotBall: false,
      showTime: true,
      timeoutNextImage: null,
      timeoutShowTime: null,
      timePerImage: 20, // seconds
    };
  },
  computed: {
    imagesAvailable() {
      const adsData = this.$store.getters.getAds;
      const imgs = adsData.imagens || [];

      if (imgs?.length) {
        this.setImages(imgs);
        return true;
      }

      return false;
    },
  },
  mounted() {
    this.showTimeContainer();
  },
  methods: {
    setImages(imagens) {
      this.imagens = imagens;

      // Set the first image
      this.setCurrentImage();
    },
    setCurrentImage() {
      const img = this.imagens[this.currentId];
      this.currentImageURL = img?.url;

      if (img.tempoExibicao && img.tempoExibicao !== 20) {
        this.timePerImage = img.tempoExibicao;
      }

      this.jackpotBall = img?.qtdAtualBolasJackpot;
      this.jackpotValue = img?.valorAtualJackpot;
      this.showJackpotBall = img?.isJackpot || false;

      // Start animation
      this.slideInAnimation();

      if (this.showJackpotBall) {
        this.slideInJackpotAnimation();
      }
    },
    nextImageId() {
      this.currentId = (this.currentId + 1 >= this.imagens.length) ? 0 : this.currentId + 1;
      this.setCurrentImage();
    },
    getPhrase(phraseId) {
      const phrase = APP_UTILS.getPhrase(phraseId);
      return phrase;
    },
    showTimeContainer() {
      this.timeoutShowTime = setInterval(() => {
        this.showTime = !this.showTime;
      }, 5000);
    },
    slideInAnimation() {
      const image = document.getElementById('currentImage');

      if (image) {
        const tl = gsap.timeline();

        this.useRAF();
        image.style.left = '110%';

        tl.to(image, {
          duration: 1,
          ease: 'sine.inOut',
          left: '0%',
          onComplete: () => gsap.delayedCall(this.timePerImage, this.slideOutAnimation),
          callbackScope: this,
        });
      }
    },
    slideInJackpotAnimation() {
      const container = document.getElementById('containerJackpot');

      if (container) {
        const tl = gsap.timeline();

        this.useRAF();
        container.style.left = '110%';

        tl.to(container, {
          duration: 1,
          ease: 'sine.inOut',
          left: '50%',
          onComplete: () => gsap.delayedCall(this.timePerImage, this.slideOutJackpotAnimation),
          callbackScope: this,
        });
      }
    },
    slideOutAnimation() {
      const image = document.getElementById('currentImage');

      if (image) {
        const tl = gsap.timeline();
        const callback = () => {
          this.nextImageId();
        };

        this.useRAF();
        tl.to(image, {
          duration: 0.5,
          ease: 'sine.inOut',
          left: '-100%',
          onComplete: () => callback(),
          callbackScope: this,
        });
      }
    },
    slideOutJackpotAnimation() {
      const container = document.getElementById('containerJackpot');

      if (container) {
        const tl = gsap.timeline();

        this.useRAF();
        tl.to(container, {
          duration: 0.5,
          ease: 'sine.inOut',
          left: '-100%',
        });
      }
    },
    useRAF() {
      // The animations keep running without focus
      gsap.ticker.lagSmoothing(false);
    },
  },
};

</script>
